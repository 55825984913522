.wrapper{
    display: flex;
    height: 750px;
}
.info{
    flex-grow: 1;
    display: flex;
    align-items: center;
}
.info-wrapper{
    display: flex;
}
.line-block{
    width: 140px;
    height: 100%;
    margin-right: 25px;

}
.line{
    height: 1px;
    width: 100%;
    background-color: #272727;
    margin: 56px 0 60px;
}
.info-block-line-title{
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}
.info-block-title{
    display: flex;
    align-items: center;
    height: 107px;
    font-size: 56px;
    font-family: Prata;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 66px;
    letter-spacing: normal;
    color: #272727;
}
.info-block-items{
    margin-left: 165px;
}
.info-block-item{
    display: flex;
    align-items: center;
    margin-bottom: 40px;
}
.info-block-item-icon-block{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    margin-right: 15px;
}
.info-block-item-text{
    font-family: ProximaNova;
    display: flex;
    flex-direction: column;
    white-space: pre-wrap;
}

@media (max-width: 767px) {
    .wrapper-contacts {
        height: auto;
        flex-direction: column-reverse;
    }
    .wrapper-contacts .info-wrapper {
        display: block;
    }
    .wrapper-contacts .line-block {
        display: none;
    }
    .wrapper-contacts .info-block-title {
        height: auto;
        font-size: 28px;
        display: block;
    }
    .wrapper-contacts .info-block-line-title {
        display: block;
        text-align: center;
    }
    .wrapper-contacts .image-block {
        padding-top: 100%;
        background: url(../../assets/images/contacts_bg.png) left top no-repeat;
        background-size: 100%;
    }
    .wrapper-contacts .image-block img {
        display: none;
    }
    .wrapper-contacts .info-block-items {
        margin: 0;
        padding: 0;
    }
    .wrapper-contacts .info-block-item {
        display: flex;
        flex-direction: column;
        margin-bottom: 48px;
    }
    .wrapper-contacts .info {
        display: block;
        padding: 0 16px;
        text-align: center;
    }
    .wrapper-contacts .info-block-item-text {
        font-size: 16px;
        line-height: 22px;
    }
    .wrapper-contacts .info-block-item-icon-block {
        margin: 0 0 16px 0;
    }
}