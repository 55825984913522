.Content {
    padding: 92px 0;
    display: flex;
    flex-direction: column;
    max-width: 1440px;
    margin: 0 auto;
}

.Items {
    padding: 0 55px;
}

.Item {
    /* width: 460px; */
    margin-bottom: 30px;
    display: flex;
}

.Item:hover .Description {
    text-decoration: underline;
}

.Icon {
    margin-right: 18px;
    margin-top: 5px;

}

.Title {
    font-size: 19px;
    font-weight: 500;
    height: 30px;
}

.Description {
    font-size: 14px;
    font-weight: 500;
}

.radio {
    width: 25px;
    height: 25px;
    margin: 0;
}

.radio:focus {
    outline: none;
}

.radio:checked {
    --swiper-theme-color: transparent
}
.radio:checked::before,
.radio input[type="checkbox"] {
    position:absolute;
    clip: rect(0,0,0,0);
    clip: rect(0 0 0 0);
}

.radio:checked,
.radio input[type="checkbox"] + label::before {
    content: url('../../assets/images/auth/oval-on.svg');
    appearance: none;
}

.radio input[type="checkbox"]:checked + label::before {
    content: url('../../assets/images/auth/oval-off.svg');
    appearance: none;
}

/* content_buttons */

.content_buttons{
    /* padding-left: 50px; */
    display: flex;
    flex-direction: column!important;
}

/* ica_card_link */
.ica_card_link{
    text-decoration: none;
    width: 400px;
    margin-bottom: 1rem;
    border-radius: 8px;
    border: 1px solid  #272727;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: 25px;
    margin-top: 50px;
    color: #272727;
    padding: 10px 0;
    font-weight: 300;
}
.ica_card_link:hover{
    background-color: #272727;
    color: #e5c496;
}