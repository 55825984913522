.title-Input {
    object-fit: contain;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    /* margin: 15px 0 15px 0; */
}

.title-Text-Input {
    align-items: center;
    height: 40px;
    font-family: ProximaNova;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    line-height: 40px;
}

.input {
    width: calc(100% - 50px);
    height: 40px;
    border-radius: 8px;
    border: solid 1px #000000;
    object-fit: contain;
    font-family: ProximaNova, serif;
    font-size: 14px;
    padding-left: 10px;
}

.input:focus {
    border: solid 1px #e5c496;
    outline: none;
}

input[type='number'] {
    -moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

@media (max-width: 767px) {
    .title-Input .title-Text-Input {
      height: auto;
      font-size: 16px;
      color: #4C4C4C80;
      line-height: 32px;
    }
}