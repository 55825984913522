a,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: ProximaNova;
  margin: 0;
  padding: 0;
}

body {
  width: 100vw;
  min-width: 100vh;
  overflow-x: hidden;
}

.slick-next:before, [dir=rtl] .slick-prev:before, .slick-prev:before {
  display: none;
}

.hovered-icon {
  cursor: pointer;
}
.hovered-icon:hover {
  text-decoration: underline;
}

.show-mobile {
  display: none;
}

@media (max-width: 767px) {
  body {
    width: auto;
    min-width: auto;
  }

  * {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    line-height: 100%;
    outline: none;
  }

  .show-desktop {
    display: none;
  }
  
  .show-mobile {
    display: inherit;
  }

  .form-error-block {
    padding: 16px;
    margin: 24px 0 0;
    background: #E543381A;
    border-radius: 8px;
  }
  .form-error-block-row {
    margin-bottom: 8px;
  }
  .form-error-block-row:last-child {
    margin-bottom: 0;
  }
}