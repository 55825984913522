.Home {
  background-color: #fafafa;
}

.smallCardsSlider {
  /* display: flex;
  flex: 0 0 100%;
  width: auto;
  max-width: 100vw!important; */
  min-height: 125px;
  background-color: transparent;
}

.smallCardsSliderCard {
  padding-right: 0;
  width: 169px;
  object-fit: contain;
  position: relative;
}

.smallCardsSliderCard img {
  width: 169px;
  height: auto;
  box-shadow: 0 9px 16px 0 rgba(0, 0, 0, 0.16);
  border-radius: 10px;
}

.cards-header {
  font-family: Prata;
  font-size: 56px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  color: #272727;
  width: 100%;
  text-align: center;
  margin-top: 111px;
}

.cards-subheader {
  font-family: ProximaNova;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.61;
  letter-spacing: normal;
  text-align: center;
  color: #4c4c4c;
  margin-top: 16px;
  margin-bottom: 55px;
}

.tabList {
  display: flex;
  flex: 0 0 100%;
  justify-content: center;
  padding-top: 13px;
}

.tabList-tabs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style: none;
  border: solid 1px #272727;
  border-radius: 8px;
  overflow: hidden;
  padding: 0;
}

.tabList-tab {
  width: 181px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #272727;
  font-family: ProximaNova;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #272727;
  cursor: pointer;
}
.tabList-tab:hover {
  text-decoration: underline;
}

.tabList-tab-active {
  color: #d7c29a;
  background-color: #272727;
}

.tabList-tab:last-child {
  border-right-width: 0px;
}

.tabContent {
  margin-top: 118px;
}

.tabPanel {
  max-width: 100vw;
  padding: 0 94px;
}

@media (max-width: 1200px) {
  .tabPanel {
    padding: 0;
    margin: 0 auto 60px;
  }
}

.selectedCardSlider {
  width: 421px;
  display: flex;
}

.cardInfo-container {
  margin-top: 88px;
}

.benefits {
  position: relative;
  margin-top: 58px;
  padding: 0 165px;
}

.benefits h3 {
  object-fit: contain;
  font-family: Prata;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.58;
  letter-spacing: normal;
  text-align: center;
  color: #272727;
  padding: 0 15px;
}

.line {
  /* content: ''; */
  /* position: absolute; */
  width: 39.4%;
  height: 1px;
  object-fit: contain;
  opacity: 0.6;
  height: 1px;
  background-color: #6c6c6c;
  margin-bottom: 2px;
  margin: 0!important;
}

.benefits-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.benefits-header h3 {
  white-space: nowrap;
}

.benefits-content {
  display: flex;
  flex: 0 0 100%;
  justify-content: center;
  margin-top: 65px;
}

.benefits-content-card {
  display: flex;
  flex: 0 0 33%;
  max-width: 350px;
  min-height: 390px;
  flex-direction: column;
  margin: 0 10px;
  padding: 31px 41px;
  background-color: #fff;
  box-sizing: border-box;
}

.benefits-content-card-title {
  font-family: Prata;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #272727;
  margin-top: 21px;
  min-height: 50px;
}

.benefits-content-card-text {
  font-family: ProximaNova;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: center;
  color: #4c4c4c;
}

.benefits-content-card .hr {
  margin: 15px auto 17.5px auto;
  width: 49px;
  height: 1px;
  background-color: #d7c29a;;
}

.news h3 {
  font-family: Prata;
  font-size: 56px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  color: #272727;
  margin-top: 106px;
  text-align: center;
}

.news .text {
  font-family: ProximaNova;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.61;
  letter-spacing: normal;
  text-align: center;
  color: #4c4c4c;
  width: 750px;
  margin: 16px auto 0;
}

.news-block {
  display: flex;
  flex-direction: row;
  padding: 95px 147px 100px;
  justify-content: center;
  position: relative;
  max-width: 1440px;
  margin: 0 auto;
}

.news-item {
  margin: 0 15px;
  position: relative;
  /* max-height: 642px; */
  overflow: hidden;
}

.news-item:nth-child(odd) {
  margin-top: 175px;
  position: relative;
}

.news-item-img {
  width: 255px;
  height: 259px;
  object-fit: cover;
  z-index: 0;
  display: block;
  position: relative;
  margin-left: 25px;
  background-color: #eeeeee;
}

.news-item:nth-child(even) .news-item-img{
  height: 379px;
}

.news-item-content {
  max-width: 255px;
  background-color: #fff;
  /*margin-top: -50%;*/
  display: block;
  position: relative;
  z-index: 1;
  padding: 10% 14.1%;
  max-height: 642px;
  min-height: 595px;
  margin-right: 15px;
}

.news-item:nth-child(odd) .news-item-content {
  margin-top: -50px;
}

.news-item:nth-child(even) .news-item-content {
  margin-top: -210px;
}

.news-item-title {
  font-family: Prata;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
  color: #272727;
}
.news-item-text {
  font-family: ProximaNova;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: #9b9b9b;

  display: block;
  text-overflow: ellipsis;
  word-wrap: break-word!important;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /* max-height: 6.24em; */
}

.separate {
  position: absolute;
  top: -450px;
  height: 100%;
  width: 1000px;
  background-color: #fafafa;
  right: calc(100% - 75px);
  transform: rotate(20deg);
}

.hr {
  width: 49px;
  height: 1px;
  background-color: #e5c496;
  margin: 25px 0;
}

.link-to-news {
  font-family: ProximaNova;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: #000000;
  margin-top: 127px;
  display: block;
  position: relative;
  width: fit-content;
  position: absolute;
  bottom: 58px;
}

.link-to-news:hover {
  color: #d7c29a;
}

.link-to-news::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  background-color: #000;
}

.link-to-news:hover::before {
  background-color: #d7c29a;
}

.more-news {
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.more-news:hover div{
  color: #d7c29a!important
}

.rewards {
  display: flex;
  flex: 0 0 88.5%;
  max-width: 1440px;
  margin: 0 auto;
}

.reward {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-position: center center!important;
  background-repeat: no-repeat!important;
  background-size: cover!important;
  overflow: hidden;
}

.reward::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(250, 250, 250, 0.3);
}

.reward::after {
  content: '';
  position: absolute;
  left: 282px;
  right: 0;
  top: 0;
  bottom: -1000px;
  background-color: #fafafa;
  transform: rotate(17deg);
}

.reward-img {
  width: 540px;
  height: 530px;
  padding: 10px;
  background-color: #fafafa;
  z-index: 1;
}
.reward-img-container {
  z-index: 1;
  position: relative;
  padding: 119px 0 84px 166px;
}

.reward-content {
  background-color: #fafafa;
  z-index: 1;
  margin-left: 80px;
  margin-right: 147px;
}

.reward-content-title {
  height: 95px;
  font-family: Prata;
  font-size: 56px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  color: #272727;
  margin-top: 156px;
}

.reward-content-text {
  font-family: ProximaNova, serif;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.61;
  letter-spacing: normal;
  text-align: justify;
  color: #4c4c4c;
  width: 476px;
}

.arrow {
  position: absolute;
  background: url('../../assets/images/arrow.svg') center center no-repeat;
  height: 64px;
  width: 42px;
  background-color: #fafafa;
  top: calc(50% - (64px / 2));
  border-top-left-radius: 100%;
  border-bottom-left-radius: 100%;
  cursor: pointer;
}

.arrow-left {
  left: 176px;
  transform: rotate(180deg);
}

.arrow-right {
  right: 10px;
}

.inactive {
  opacity: .5;
}

.slick-list, .slick-slider, .slick-track {
  width: 100%;
}
.slick-list {
  max-width: 1440px;
  margin: 0 auto;
}

.slick-track {
  max-width: 1440px;
  display: flex;
}

.smallCardsSliderCard-padding {
  padding: 0 94px;
}

@media (max-width: 767px) {
  .tabPanel {
    margin-bottom: 0;
  }
  .tabPanel.react-tabs__tab-panel--selected {
    margin-bottom: 60px;
  }
  .slick-track {
    display: block;
    max-width: unset;
  }
  .cards-header {
    margin: 0;
    font-size: 28px;
  }
  .cards-subheader {
    padding: 0 40px;
    font-size: 16px;
    color: #4C4C4C80;
  }
  .tabList {
    padding: 0 16px;
    margin-bottom: 46px;
  }
  .tabList-tabs {
    margin: 0;
  }
  .tabContent {
    margin: 0;
  }
  .slick-slide {
    /* width: 100%; */
  }
  .cardInfo-container .card-info-wrapper {
    display: block;
    min-height: 0;
  }
  .benefits {
    margin: 0;
    padding: 0;
  }
  .benefits h3 {
    font-size: 28px;
  }
  .benefits-header {
    margin: 0 16px;
  }
  .benefits-content {
    display: block;
    padding: 16px;
  }
  .benefits-content-card-title {
    min-height: 0;
    padding: 0 40px;
  }
  .benefits-content-card {
    padding: 16px;
    margin: 0 0 24px 0;
    max-width: unset;
    min-width: 0;
    min-height: 0;
    background: #fff;
  }
  .benefits-content-card-text {
    color: #4C4C4C80;
    font-size: 16px;
  }

  .smallCardsSliderCard-padding {
    padding: 0;
  }
  .benefits-img-mobile {
    display: block;
    width: 80px;
    margin: 0 auto 24px;
  }
}