.profile-modal {
  max-width: 430px;
  margin: 0 auto;
}

.profile-modal p {
  font-family: AvenirNext;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.56px;
  text-align: center;
  color: #262524;
}

.profile-form {
  background-size: contain;
}
.profile-content {
  display: flex;
  margin: 0 auto;
}
.profile-c1 {
  width: 11.2%;
}
.profile-line {
  border-top: #000000 1px solid;
  margin-top: 125px;
}
.profile-c2 {
  width: 100%;
  text-align: left;
  padding-left: 20px;
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
  padding-bottom: 61px;
  background-image: url(../../assets/images/profile/bg-01.png);
}
.profile-t1 {
  margin-top: 90px;
  font-family: Prata;
  font-size: 56px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  color: #272727;
}

.profile-row {
  margin-top: 15px;
}

.profile-input {
}
.profile-input-label {
  display: block;

  font-family: ProximaNova;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}
.profile-input-row {
  margin-top: 10px;
  display: flex;
}
.profile-input-input {
  width: 270px;
  min-width: 270px;
  height: 40px;
  padding: 0 10px;
  outline: none;
  border-radius: 8px;

  border: solid 1px #000000;
  font-family: ProximaNova;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}
.profile-input-error {
  margin-left: 10px;
  align-items: center;
}
.profile-input-icon {
  margin-left: 10px;
}
/* endinput */

/* error */
.profile-error {
  display: none;
}
.profile-has-error {
  display: flex;
}
.profile-error-i {
  min-width: 12px;
  width: 12px;
  height: 13px;
  border-radius: 12px;
  margin-right: 5px;

  background-color: #d0021b;
  font-family: ProximaNova;
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}
.profile-error-t {
  text-transform: uppercase;

  font-family: ProximaNova;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.39px;
  color: #d0021b;
}
/* end-error */

/* btn*/
.profile-btn-group {
  margin-top: 19px;
  align-items: center;
  display: flex;
  width: 23.8%;
}
.profile-btn {
  height: 40px;
  border-radius: 8px;
  border: solid 1px #000000;
  cursor: pointer;

  font-family: ProximaNova;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 40px;
  letter-spacing: normal;
  text-align: center;
}
.profile-b1 {
  width: 100%;
  margin-right: 15px;
  color: #000000;
}
.profile-b2 {
  width: 100%;
  margin-right: 15px;
  color: #d7c29a;
  background-color: #272727;
}
.profile-b3 {
  width: 100%;
  color: #d7c29a;
  background-color: #272727;
}
.profile-btn-loader {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* end-btn*/

.profile-input {
  font-family: ProximaNova;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}

.profile-input input {
  width: 100%;
}

.input-wrapper {
  position: relative;
}

.input-wrapper img{
  position: absolute;
  right: -60px;
  top: calc(60%);
}

.modal-content {
  width: 100%;
  height: 100%;
}

.modal-input {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.modal-input div {
  display: flex;
  flex: 0 0 100%;
  justify-content: space-between;
  font-family: ProximaNova;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #88888b;
  margin-bottom: 14px;
}

.modal-input input {
  display: flex;
  flex: 0 0 100%;
  min-height: 46px;
  margin-bottom: 17px;
  border-radius: 2px;
  background-color: #f3f4f4;
  border-image: none;
  border: none;
  padding-left: 5px;
}

.modalSuccesfulFormSave-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modalSuccesfulFormSave-text {
  width: 268px;
  height: 48px;
  font-family: AvenirNext;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.56px;
  text-align: center;
  color: #262524;
}

.auth-input-error {
  margin-left: 10px;
  align-items: center;
}

.auth-error {
  display: flex;
}

.auth-error-i {
  min-width: 12px;
  width: 12px;
  height: 13px;
  border-radius: 12px;
  margin-right: 5px;
  background-color: #d0021b;
  font-family: ProximaNova;
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}
.profile-form-block {
  width: 22.8%;
}
.profile-button .button {
  width: 104%;
  margin-top: 19px;
}
.modalSuccesfulFormSave-wrap {
  margin-top: 64px;
}

@media (max-width: 767px) {
  .profile-c1 {
    display: none;
  }
  .profile-c2 {
    padding: 100% 16px 16px;
    background-position: top center;
    background-size: 100% auto;
    background-image: url(../../assets/images/profile/profile_bg_mobile.png);
  }
  .profile-t1 {
    margin: 16px 0 32px 0;
    text-align: center;
    font-size: 28px;
  }
  .profile-form-block {
    width: 100%;
  }
  .profile-form-block {
    padding: 16px;
    margin-bottom: 100px;
    background: #fff;
    border-radius: 16px;
  }
  .input-wrapper {
    margin: 0 0 24px 0;
  }
  .profile-input input {
    height: 50px;
    border-color: #4C4C4C1A;
  }
  .profile-button {
    height: 60px;
    padding-top: 10px;
    margin: 0 -16px -85px;
  }
  .profile-button .button {
    position: relative;
    width: 100%;
    height: 60px;
    padding: 0 60px;
    justify-content: flex-start;
    margin: 0;
    background: #fff;
  }
  .profile-button .button span {
    color: #0F0F11;
    font-size: 18px;
    font-family: Prata;
  }
  .profile-button .button:before {
    content: '';
    position: absolute;
    left: 16px;
    top: 50%;
    width: 34px;
    height: 34px;
    margin-top: -17px;
    background-image: url(../../assets/images/profile/change_pass_ico.svg);
    background-position: center;
    background-repeat: no-repeat;
  }
  .profile-button .button:after {
    content: '';
    position: absolute;
    right: 16px;
    top: 50%;
    width: 34px;
    height: 34px;
    margin-top: -17px;
    background-image: url(../../assets/images/arrow_mobile.svg);
    background-position: center;
    background-repeat: no-repeat;
  }

  .modal-input {
    margin-bottom: 24px;
  }

  .modal-input:last-child {
    margin: 0;
  }

  .modal-input > div {
    margin-bottom: 8px;
    font-size: 16px;
  }

  .modal-input input {
    height: 50px;
    margin: 0;
    border: 1px solid #4C4C4C1A;
    background: #FAFAFA;
    border-radius: 8px;
  }
  .close-icon {
    position: absolute;
    right: 16px;
    top: 16px;
    padding: 10px;
  }
  .title {
    height: 60px;
    padding: 0 16px;
    line-height: 60px;
    border-bottom: 1px solid #4c4c4c15;
    font-family: Prata;
    text-align: left;
    font-size: 18px;
    font-weight: normal;
    -webkit-text-stroke: unset;
  }
  .content {
    padding: 24px;
    margin: 0;
  }
  .buttonsBlock {
    gap: 16px;
    padding: 24px;
    margin: 0;
    justify-content: space-between;
  }
  .buttonsBlock > * {
    flex: 1;
    margin: 0;
  }
  .modalSuccesfulFormSave-wrap {
    margin: 0;
  }
  .modalSuccesfulFormSave-wrap img {
    display: none;
  }
  .modalSuccesfulFormSave-text {
    font-size: 18px;
    font-family: 'PRATA';
  }
  .pass-success-modal .title {
    font-size: 0;
  }
  .auth-error.auth-input-error.auth-has-error {
    padding: 16px;
    gap: 4px;
    margin: 16px 0 0 0;
    border-radius: 10px;
    background-color: #E543381A;
    text-align: left;
  }
}