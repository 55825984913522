.card-images-slider {
  max-width: 376px;
  height: 373px;
}

.card-info {
  &-wrapper {
    display: flex;
    flex-direction: row;
    max-width: 1100px;
    min-height: 387px;
    margin: 0 auto;
    justify-content: center;
    align-items: flex-start;
    // box-shadow: 0 9px 16px 0 rgba(0, 0, 0, 0.16);
    position: relative;
  } 
  &-description {
    display: flex;
    flex: 0 0 50%;
    flex-direction: column;
    justify-content: center;
    max-width: 396px;
    margin-left: 92px;
    &-title {
      font-family: Prata;
      font-size: 30px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.27;
      letter-spacing: normal;
      color: #272727;
    }

    &-text {
      font-family: ProximaNova;
      font-size: 16px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.75;
      letter-spacing: normal;
      color: #4c4c4c;
      margin-top: 25px;
    }

    &-buttons {
      display: flex;
      flex-direction: row;
      margin-top: 85px;
      justify-content: space-between;

      .button {
        display: flex;
        flex: 0 0 45%;

        .white {
          background-color: #fff;
        }
      }
    }
  }
}

.card-info-wrapper-container {
  position: relative;
  max-width: 421px;
  padding-right: 50px;
}

.prev-arrow {
  height: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  left: -8%;
  top: -10%;
  transform: rotate(180deg);
}

.next-arrow {
  height: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  right: 20px;
  top: -10%;
}

@media (max-width: 767px) {
  .card-info-description {
    padding: 16px;
    margin: 0;
  }
  .card-info-description-title {
    margin-bottom: 8px;
  }
  .card-info-description-text {
    margin: 0 0 24px;
    color: #4C4C4C80;
  }
  .card-info-description-buttons {
    gap: 16px;
    margin: 0;
  }
  .card-images-slider {
    max-width: 100%;
    height: auto;
  }
  .prev-arrow {
    width: 60px;
    left: 0;
    top: 0;
    justify-content: center;
  }
  .next-arrow {
    width: 60px;
    right: 0;
    top: 0;
    justify-content: center;
  }

  .card-info-wrapper-container {
    max-width: unset;
    padding-left: 60px;
    padding-right: 60px;
  }
}