.title-Text {
    /*width: 557px;*/
    height: 107px;
    font-family: Prata, serif;
    font-size: 56px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 107px;
    letter-spacing: normal;
    color: #272727;
    margin-left: 24px;
    white-space: nowrap;
}

.Title {
    display: flex;
    align-items: center;
    height: auto!important;
}

.Are-you-ready {
    width: 329px;
    height: 87px;
    font-family: ProximaNova, serif;
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.61;
    letter-spacing: normal;
    color: #4c4c4c;
    margin-left: 163px;
    margin-top: 20px;
    text-align: left;
}

.Path-2 {
    width: 140px;
    height: 1px;
    background-color: #272727;
}

@media (max-width: 767px) {
    .Path-2 {
        display: none;
    }
    .Title {
        display: block;
    }
    .title-Text {
        height: auto;
        margin: 0;
        font-size: 28px;
        line-height: 100%;
        text-align: center;
    }
    .Are-you-ready {
        width: 100%;
        height: auto;
        padding: 16px;
        margin: 0;
        color: #4C4C4C80;
        font-family: ProximaNova, serif;
        font-size: 18px;
        line-height: 1.61;
        letter-spacing: normal;
        text-align: center;
    }
}