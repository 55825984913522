.letters {
  display: flex;
  flex-direction: row;
  min-height: calc(100vh - 275px - 65px);
  position: relative;
  margin: 39px 0 0 0;
  padding-bottom: 39px;

  &-counter {
    width: 15px;
    height: 29px;
    font-family: ProximaNova;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.34px;
    color: #d0021b;
    padding-left: 5px;
  }

  &-menu {
    flex: 0 0 11.7%;
    height: 100px;
    display: block;

    &-items {
      margin-top: 22px;
    }

    &-item {
      font-family: ProximaNova;
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.86px;
      color: #4c4c4c;
      display: flex;
      flex-direction: row;
      padding-top: 10.5px;
      padding-bottom: 10.5px;
      padding-right: 31px;
      cursor: pointer;
      padding-left: 95px;

      &:hover {
        text-decoration: underline;
      }

      &.active {
        background-color: rgba(228, 196, 150, .36);
      }

      p {
        padding-left: 21px;
      }

      img {
        width: 31px;
        height: 31px;
      }
    }
  }

  &-table {
    flex: 0 0 73%;
    overflow: hidden;
    margin-left: 31px;

    table {
      width: 100%;
      border-spacing: 0 2px;
      border-collapse: unset;
      text-align: center;
      margin: 0 95px 0 0;

      tr {
        // padding: 19px 0;
        margin: 1px 0;
        height: 66px;
      }

      tr:nth-child(odd) {
        background-color: #efefef;
      }

      tr:nth-child(even) {
        background-color: #e6e6e6;
      }

      td {
        font-family: ProximaNova;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #4a4a4a;
        height: 66px;
      }
    }

    img {
      cursor: pointer;
    }

    thead {
      tr {
        background-color: #272727!important;
      }
      td {
        border: none;
        border-spacing: 0;
        // padding: calc((65px - 16px) / 2) 0!important;
        padding: 0!important;
        font-family: ProximaNova;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #e5c496;
      }
    }
  }
}

.button-wrap {
  padding-left: 95px;
}

.last-col {
  display: flex;
  flex-direction: row;
  padding: 0 31px;
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 12.5px;
    padding-top: 14px;
  }

  p {
    font-family: ProximaNova;
    font-size: 9px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #4a4a4a;
    margin: 9.5px 0;
  }
}

.first-col {
  // display: flex;
  // flex-direction: row;
  // padding: 14px 0 24px 45px;
  height: 68px;
  max-height: 68px;
  min-height: 68px;
  align-items: center;

  div:last-child {
    margin-left: 17px;
  }
}

.hovered-row {
  cursor: pointer;
}

@media (min-width: 1024px) {
  .hovered-row:hover td {
    border-top: 1px solid #e5c79d;
    border-bottom: 1px solid #e5c79d;
    // box-shadow: 2px 0 1px 2px #e5c79d;
  }

  .hovered-row:hover .first-col {
    border-left: 1px solid #e5c79d;
    height: 71px;
    max-height: 71px;
    min-height: 71px;
  }

  .hovered-row:hover td:last-child {
    border-right: 1px solid #e5c79d;
  }
}

.hovered-row td {
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}

.hovered-row .first-col {
  border-left: 1px solid transparent;
}

.hovered-row td:last-child {
  border-right: 1px solid transparent;
}
.letter {
  &-tabs {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: row;
  }
  &-tab {
    width: 263px;
    height: 42px;
    margin: 1px 0 0;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    background-color: #272727;
    display: flex;
    flex-direction: row;
    padding: 0 23px 0 39px;
    justify-content: flex-end;
    cursor: pointer;

    &-title {
      font-family: ProximaNova;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #e5c496;
      line-height: 43px;
    }
    &-buttonBlock {
      font-family: ProximaNova;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #e5c496;
      line-height: 43px;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: auto;
    }

    &-maximize {
      font-family: ProximaNova;
      font-size: 30px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #e5c496;
      line-height: 43px;
      margin-right: 21px;
      cursor: pointer;
    }

    &-close {
      color: #e5c496;
      cursor: pointer;
      g {
        fill: #e5c496
      }
    }
  }
}

.letter-modal-delete {
  width: 260px;
  height: 51px;
  margin: 18px auto 14px auto;
  font-family: ProximaNova;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #88888b;
}
.letter-buttons {
  display: flex;
}

@media (max-width: 767px) {
  .letters {
    position: relative;
    margin: 0;
    padding: 134px 16px 16px;
    display: block;
  }
  .letters:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 27px;
    width: 92px;
    height: 92px;
    margin-left: -46px;
    background-position: top center;
    background-image: url(../../assets/images/letters_mobile.svg);
    background-repeat: no-repeat;
  }
  .letters-menu {
    height: auto;
    margin-bottom: 48px;
  }
  .letters-menu .button-wrap {
    display: flex;
    padding: 0;
    margin-bottom: 32px;
    align-items: center;
  }
  .letters-menu .button-wrap .button {
    height: 50px;
    line-height: 50px;
    font-size: 16px;
  }
  .letters-menu .button-wrap::before {
    content: 'Letters';
    width: 50%;
    font-size: 28px;
    font-family: ProximaNova-Medium;
  }
  .letters-menu .button-wrap > div {
    width: 50%;
    padding-left: 20px;
  }
  .letters-menu .button-wrap .button span {
    font-size: 16px;
  }
  .letters-menu .button span.button-big-text {
    font-size: 28px;
    margin-right: 10px;
  }
  .letters-menu-items {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0;
    overflow-x: scroll;
  }
  .letters-menu-items:before {
    content: '';
    flex: none;
    width: 40px;
    height: 40px;
    background-position: top center;
    background-image: url(../../assets/images/hand_mobile.svg);
    background-repeat: no-repeat;
  }
  .letters-menu-item {
    padding: 9px 12px;
    margin: 0 0 0 12px;
    align-items: center;
  }
  .letters-menu-item p {
    padding-left: 16px;
  }
  .letters-menu-item:hover {
    text-decoration: none;
  }
  .letters-menu-item.active {
    border-radius: 24px;
  }
  .letters-table {
    margin: 0;
  }
  .letters-table table {
    display: block;
  }
  .letters-table table thead {
    display: none;
  }
  .letters-table table tbody {
    display: block;
    width: 100%;
  }
  .letters-table table tr {
    position: relative;
    display: block;
    height: auto;
    padding: 16px;
    margin-bottom: 16px;
    background-color: #fff;
    border-radius: 8px;
  }
  .letters-table table tr:nth-child(odd),
  .letters-table table tr:nth-child(even) {
    background-color: #fff;
  }
  .letters-table table td {
    display: block;
    height: auto;
    background-color: #fff;
    text-align: left;
    font-size: 16px;
    color: #0F0F11;
  }
  .letters-table table td.no-letters {
    height: 180px;
    line-height: 180px;
    text-align: center;
  }
  .letters-table .first-col {
    display: none;
  }
  .letters-table table td:nth-child(2) {
    margin-bottom: 28px;
  }
  .letters-table table td:nth-child(3) {
    margin-bottom: 6px;
  }
  .letters-table table td:nth-child(3),
  .letters-table table td:nth-child(4) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .letters-table table td:nth-child(3):before {
    content: 'Subject';
    color: #4C4C4C80;
  }
  .letters-table table td:nth-child(4):before {
    content: 'Time';
    color: #4C4C4C80;
  }
  .letters-table table td:nth-child(3) span,
  .letters-table table td:nth-child(4) span {
    padding-left: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .letters-table table .last-col {
    padding: 16px 0 0 0;
  }
  .letters-table table .last-col > div {
    position: relative;
    width: 100%;
    height: 36px;
    padding: 0;
    margin: 0;
    gap: 8px;
    flex-direction: row;
    line-height: 36px;
    background: #FAFAFA;
  }
  .letters-table table .last-col > div p {
    color: #4C4C4C50;
  }
  .letters-table table .last-col > div:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  .letters-table table .last-col > div:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .letters-table table .last-col > div:nth-child(2):before {
    content: '';
    position: absolute;
    left: 0;
    top: 5px;
    bottom: 5px;
    width: 1px;
    background: #4C4C4C10;
  }
  .letters-table table .last-col p {
    font-size: 16px;
  }
  .letters .popup-block {
    width: auto;
    left: 16px;
    display: flex;
    right: 16px;
    top: 16px;
    bottom: 16px;
    justify-content: center;
    max-height: calc(100vh - 32px);
  }
  .letters .popup-header {
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: space-between;
    padding: 10px 16px;
  }
  .letters .popup-header .popup-header-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 18px;
    font-family: ProximaNova-Medium;
  }
  .letters .popup-header .popup-header-buttons {
    gap: 6px;
  }
  .letters .popup-header .popup-header-button {
    margin: 0;
    padding: 10px;
  }
  .letters .popup-header .popup-header-button img {
    width: 18px;
  }
  .letters .popup-header .popup-header-button-minimize {
    width: 18px;
    height: 3px;
    margin-top: 2px;
  }
  .letters .popup-content {
    // flex: 1;
  }
  .letters .popup-content form {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .letters .popup-content form .popup-textarea-block {
    // flex: 1;
  }
  .letters .popup-input-block {
    padding: 12px 0;
    font-size: 16px;
  }
  .letters .popup-input-label {
    color: #4C4C4C80;
  }
  .letters .popup-input {
    font-size: 16px;
  }
  .letters .popup-textarea {
    font-size: 16px;
    line-height: 20px;
  }
  .popup-submit-button {
    width: 100%;
  }
  .letter-tabs {
    position: fixed;
    left: 16px;
    right: 16px;
    z-index: 5;
  }
  .letter-tab {
    width: 100%;
    height: auto;
    display: flex;
    grid-gap: 16px;
    gap: 16px;
    align-items: center;
    justify-content: space-between;
    padding: 10px 16px;
  }
  .letter-tab-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 18px;
    font-family: ProximaNova-Medium;
  }
  .letter-tab-maximize,
  .letter-tab-close {
    width: 40px;
    height: 40px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .letters-menu .popup-buttons-block > div:last-child {
    width: 100%;
    gap: 16px;
  }
  .letters-menu .popup-delete-button {
    width: 100%;
    height: 50px;
    margin: 0;
    line-height: 50px;
    background: #0F0F11;
    border-radius: 6px;
    color: #D7C29A;
  }
  .letters-menu .popup-delete-button img {
    display: none;
  }
  .letters-menu .popup-delete-button .popup-delete-button-text {
    font-size: 16px;
  }
  .letter-modal-delete {
    width: 100%;
    height: auto;
    margin: 0;
    font-family: Prata;
    font-size: 18px;
    color: #0F0F11;
  }
  .letters-menu .popup-buttons-block .letter-buttons,
  .letters-menu .popup-buttons-block .letter-buttons:last-child {
    gap: 0;
    margin-bottom: 12px;
  }
  .letters-menu .letter-buttons > div {
    position: relative;
    width: 100%;
    position: relative;
    background-color: #FAFAFA;
    height: 36px;
  }
  .letters-menu .letter-buttons > div:first-child {
    border-radius: 8px 0 0 8px;
  }
  .letters-menu .letter-buttons > div:last-child {
    border-radius: 0 8px 8px 0;
  }
  .letters-menu .letter-buttons > div .popup-delete-button-text {
    font-size: 0;
  }
  .letters-menu .letter-buttons > div:before {
    content: '';
    position: absolute;
    top: 6px;
    bottom: 6px;
    left: 0;
    width: 1px;
    background: #4C4C4C20;
  }
  .letters-menu .letter-buttons > div:first-child:before {
    display: none;
  }
  .popup-buttons-block-rows {
    flex-direction: column-reverse;
  }

  .letter-button-download::after,
  .letter-button-print::after,
  .letter-button-archive::after,
  .letter-button-delete::after,
  .letter-button-favorite::after {
    content: '';
    width: 32px;
    height: 32px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -16px 0 0 -16px;
    background-position: center;
    background-repeat: no-repeat;
  }
  .letter-button-download::after {
    background-image: url(../../assets/images/letters/download_ico.svg);
  }
  .letter-button-print::after {
    background-image: url(../../assets/images/letters/print_ico.svg);
  }
  .letter-button-archive::after {
    background-image: url(../../assets/images/letters/archive_ico.svg);
  }
  .letter-button-delete::after {
    background-image: url(../../assets/images/letters/trash_ico.svg);
  }
  .letter-button-favorite::after {
    background-image: url(../../assets/images/letters/star_ico.svg);
  }
}