.cardSlider {
  padding: 0 85px 0 45px;
  max-width: 1440px;
  margin: 100px auto 0;
}

.card {
  display: flex;
  flex: 0 0 100%;
  width: auto;
  /*margin-right: 25px;*/
  margin-left: 25px;
  cursor: pointer;
  flex-direction: column;
}

.card:focus {
  outline: none;
}

.card-separator {
  width: 18px;
  height: 1px;
  background-color: #000;
  z-index: 2;
  margin: 9px auto;
}

.card-arrow-separator {
  height: 1px;
  background-color: #fafafa;
  z-index: 2;
  position: absolute;
  left: -1px;
  right: -1px;
  bottom: 0;
  height: 54px;
}

.card-arrow-separator::before {
  content: '';
  background: url('../../assets/images/arrow-down.svg') center center no-repeat;
  width: 25px;
  height: 25px;
  position: absolute;
  left: calc(50% - 12.5px);
}

.toggleCard {
  position: relative;
}

.toggleCard::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255,255,255, 0.5);
  border-radius: 17px;
}

.toggleCardSmall::after {
  content: '';
  position: absolute;
  top: 15px;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255,255,255, 0.5);
}

.unsetBoxShadow {
  box-shadow: none!important;
}

.inactive g {
  opacity: .8;
  stroke: #000;
}

.card-item {
  cursor: pointer;
  width: 400px;
  height: 253px;
  border-radius: 17px;
  background-size: contain;
  overflow: hidden;
}

.due {
  margin-left: auto;
  /*margin-bottom: -30px;*/
  width: 106px;
  height: 30px;
  padding-left: 30px;
  font-size: 18px;
  line-height: 30px;
  border-top-right-radius: 10px;
  position: relative;
  overflow: hidden;
  color: #fff;
  z-index: 1;
  cursor: pointer;
}

.due-space {
  padding-top: 30px;
}

.due:hover {
  text-decoration: underline;
}

.due::after {
  content: '';
  position: absolute;
  width: 1100%;
  height: 1100%;
  top: 20px;
  right: -683%;
  background: #b70000;
  transform-origin: 54% 0;
  transform: rotate(65deg);
  z-index: -1;
  overflow: hidden;
}

.currency {
  margin-top: 50px;
  font-size: 29px;
  margin-left: 19px;
  color: #fff

}
.selected-card-currency {
  font-size: 29px;
  margin-left: 19px;
  color: #fff

}

.card-item-number {
  margin-top: 26px;
  font-size: 20px;
  margin-left: 26px;
  letter-spacing: 6px;
  color: #fff;
  white-space: nowrap;
}

.selected-card-info {
  width: 399px;
  height: 82px;
  background-color: #4a4a4a;
  display: flex;
}

.selected-card-text {
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.66px;
  color: #cfcfd4;
  padding-left: 4px;
}

.textOverCard {
  font-family: ProximaNova;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #272727;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 6;
}

.card-current {
  margin-top: 50px;
  margin-left: 25px;
}

@media (max-width: 767px) {
  .card-item {
    width: auto;
    height: auto;
    aspect-ratio: 320/205;
    background-repeat: no-repeat;
  }

  .card {
    margin-left: 0;
  }

  .swiper-slide img {
    width: 100%;
  }

  .smallCardsSliderCard {
    width: 75vw;
    padding-left: 16px;
  }
  
  .smallCardsSliderCard img {
    width: 100%;
  }
  .card-separator {
    display: none;
  }
  .card-arrow-separator {
    display: none;
  }
  .textOverCard {
    display: none;
  }
  .smallCardsSlider .slick-prev,
  .smallCardsSlider .slick-next {
    display: none !important;
  }
  .toggleCardSmall::after {
    display: none;
  }
  .card-current {
    display: none;
  }
  .card-item-number {
    font-size: 13px;
  }
}
