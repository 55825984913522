a {
  text-decoration: none;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  padding-left: 34px;
  padding-right: 22px;
  background-color: #fff;
}

.active-link:after {
  content: ' ';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 55px;
  height: 4px;
  background-color: #000000;
  margin: 0 auto
}

.logo-wrapper {
  width: 50px;
  height: 50px;
}

.logo {
  object-fit: contain;
}

.nav-menu {
  display: flex;
  height: 100%;
  min-width: 400px;
  justify-content: space-between;
  margin-left: 55px;
}
.nav-menu a {
  font-size: 22px;
  font-weight: 300;
  letter-spacing: 0.5px;
  color: #272727;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-width: 55px;
}
.nav-menu a:hover {
  opacity: 0.5;
}
.nav-menu a:hover:after {
  content: ' ';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 55px;
  height: 4px;
  background-color: #000000;
  margin: 0 auto
}

.letters-count {
  background: #d0021b;
  border-radius: 4px;
  color: white;
  font-size: 60%;
  margin-left: 4px;
  padding: 0 5px;
  position: relative;
}

header .button {
  width: 90px;
  height: 40px;
}

header .button:hover {
  background-color: #d7c29a;
  /*width: 90px;*/
  /*height: 40px;*/
}

.buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
}

.user-profile-icon #Profile {
  fill: #000000;
}

.profile:hover .user-profile-icon #Profile {
  fill: #d7c29a;
}


/* Burger Button */
.burger_menu_button{
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
}
.burger_menu_button svg{
  font-size: 30px;
}
.side_menu{
  background-color: #fff;
  width: 300px;
  height: 100vh;
  position: fixed;
  top: -100%;
  right: 8%;
  z-index: 99999;
  transition: 850ms;
}
.side_menu.active{
  top: 0;
  transition: 350ms;
}

.side_menu ul{
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0px 20px;
}
.side_menu ul li{
  margin: 0.5rem 0;
  padding-bottom: 5px;
  transition: .1s;
}
.side_menu ul li::after{
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: #000;
  margin-left: auto;
  transition: width .3s;
  margin-top: 10px;
}
.side_menu ul li:hover::after{
  width: 80%;
  transition: width .3s;
}
.side_menu ul li a{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #000;
  font-weight: 300;
  font-size: 16px;
}
.side_menu ul li a img{
  margin-right: 1rem;
}


.burger_menu_button.active{
  display: flex;
  margin-left: auto;
  justify-content: right !important;
  padding: 20px 60px;
}


.profile:hover:after {
  opacity: 0.5;
  content: ' ';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 55px;
  height: 4px;
  background-color: #000000;
  margin: 0 auto;
}

.side-menu-title {
  display: none;
}

.profile_opener_button {
  display: none;
  background: none;
  border: none;
}

.profile-button__desktop {
  position: relative;
  height: 100%;
  display: flex;
  margin-right: 16px;
  width: 55px;
  justify-content: center;
}

.side_menu ul li img.menu-icon-mobile {
  display: none;
}

.nav-menu-mobile {
  display: none;
}

@media (max-width: 767px) {
  header.header {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    height: 50px;
    padding: 0 12px;
    display: block;
    background: rgba(15, 15, 17, 0.3);
    z-index: 1;
  }

  .white-header header.header {
    background-color: white;
  }
  
  header.header .logo-wrapper {
    position: absolute;
    left: 50%;
    margin-left: -25px;
  }
  
  header.header .logo-wrapper path {
    fill: white;
  }

  .white-header header.header .logo-wrapper path {
    fill: #000;
  }

  header.header .nav-menu:not(.nav-menu-mobile) {
    display: none;
  }

  .nav-menu-mobile {
    min-width: 1px;
    height: auto;
    display: block;
    padding-bottom: 15px;
    margin: 0 0 15px 0;
    border-bottom: 1px solid #4C4C4C1A;
  }

  .nav-menu-mobile:last-child {
    margin: 0;
    padding: 0;
    border: none;
  }

  .profile-button__desktop {
    display: none;
  }

  .profile_opener_button {
    display: block;
  }

  .side-menu-title {
    font-family: ProximaNova-Medium;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.6px;
    color: #4c4c4c;
    width: 100%;
    height: 62px;
    line-height: 62px;
    display: block;
    font-size: 22px;
    padding-left: 16px;
    margin-bottom: 15px;
    border-bottom: 1px solid #4C4C4C1A;
  }

  .side_menu ul li {
    height: auto;
    margin: 0;
    padding: 0;
  }

  .side_menu ul li:after {
    display: none;
  }

  .nav-menu-mobile a,
  .side_menu ul li a {
    position: relative;
    display: flex;
    width: 100%;
    height: 52px;
    padding: 15px 0 15px 16px;
    justify-content: flex-start;
    font-size: 16px;
  }

  .nav-menu-mobile a.menu-item-red {
    color: #E54338;
  }

  .nav-menu-mobile a img {
    margin-right: 1rem;
  }

  .side_menu ul li img.menu-icon-desktop {
    display: none;
  }

  .side_menu ul li img.menu-icon-mobile {
    display: inline-block;
  }

  header.header .buttons {
    /* flex-direction: row-reverse; */
    justify-content: space-between;
  }

  header.header .button-logout {
    display: none;
  }

  .side_menu {
    width: 267px;
    top: 0;
    right: -100%;
    transition-delay: 0.1s;
  }

  .side_menu_profile {
    right: auto;
    left: -100%;
  }

  .side_menu.active {
    right: 0;
    transition-delay: 0s;
  }

  .side_menu_profile.active {
    left: 0;
  }

  .side_menu:before {
    content: '';
    position: fixed;
    left: 0;
    top: 0;
    right: 267px;
    bottom: 100%;
    background: rgba(96, 96, 96, 0.6);
    opacity: 0;
    transition-property: opacity, bottom;
    transition-delay: 0s, 0.2s;
    transition-duration: 0.2s, 0s;
  }

  .side_menu_profile:before {
    right: 0;
    left: 267px;
  }

  .side_menu.active:before {
    bottom: 0;
    opacity: 1;
    transition-delay: 0.3s, 0s;
  }

  .side_menu ul {
    padding: 0;
    margin: 0;
  }

  .burger_menu_button.active {
    position: relative;
    display: block;
    padding: 0;
    width: 40px;
    height: 40px;
    margin: 9px 10px -50px auto;
  }

  .burger_menu_button.active svg {
    display: none;
  }

  .burger_menu_button.active:before,
  .burger_menu_button.active:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 22px;
    margin-left: -11px;
    border-bottom: 1px solid #000;
  }

  .burger_menu_button:before {
    transform: rotate(-45deg);
  }

  .burger_menu_button:after {
    transform: rotate(45deg);
  }

  .nav-menu-mobile a.active-link,
  .side_menu ul li a.active-link {
    background: #D7C29A20;
    font-weight: bold;
  }

  .active-link:after {
    left: auto;
    right: 0;
    top: 0;
    bottom: 0;
    width: 3px;
    height: auto;
    background: #D7C29A;
  }
  .profile_opener_button svg path {
    fill: #fff;
  }
  .white-header .profile_opener_button svg path {
    fill: #000;
  }
  .burger_menu_button svg {
    stroke: white;
  }
  .white-header .burger_menu_button svg {
    stroke: #000;
  }
}