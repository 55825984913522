.title {
  -webkit-text-stroke: 1px #0f0f11;
  font-family: ProximaNova;
  font-size: 28px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: center;
  color: #0f0f11;
}

.content {
  font-family: AvenirNext;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.56px;
  text-align: center;
  color: #262524;
  /* max-width: 230px; */
  padding: 0 50px;
  margin: 45px auto 0 auto;
}

.buttonsBlock {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex: 0 0 100%;
  justify-content: center;
  height: 100%;
  /* align-items: flex-end; */
  margin-top: 34px;
  margin-bottom: 25px;
}

.buttonsBlock div{
  margin: 0 4px;
}

.close-icon {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 22px 22px 0 0;
}
.ReactModal__Content {
  min-width: 518px;
}
@media (max-width: 767px) {
  .ReactModal__Content {
    min-width: 0;
    width: calc(100% - 32px);
  }
}