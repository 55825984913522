.popup-wrapper{
    display: none;
    z-index: 10;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: transparent;
    box-sizing: border-box;
    font-family: ProximaNova;
}
.popup-wrapper.active{
    display: block;
    background-color: rgba(0, 0, 0, .6);
}
.popup-open-button{
    display: none;
    z-index: 10;
    position: fixed;
    right: 40px;
    bottom: 40px;
    background-color: #fff;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 10px 4px rgba(0, 0, 0, .3);
}
.popup-open-button.active{
    display: flex;
}
.popup-open-button:hover{
    cursor: pointer;
}
.popup-open-button-icon{
    width: 30px;
}
.popup-block{
    display: none;
    position: absolute;
    bottom: 0;
    right: 40px;
    width: 600px;
    border-radius: 15px;
    flex-direction: column;
}

.popup-block-center {
    display: flex;
    position: relative;
    width: 600px;
    border-radius: 15px;
    flex-direction: column;
}
.popup-block.active{
    display: flex;
}
.popup-header{
    display: flex;
    justify-content: space-between;
    background-color: #272727;
    padding: 15px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}
.popup-header-text{
    color: #e5c496;
    text-transform: uppercase;
}
.popup-header-buttons{
    display: flex;
    align-items: center;
}
.popup-header-button{
    margin-left: 10px;
}
.popup-header-button:hover{
    cursor: pointer;
}
.popup-header-button-icon{

}
.popup-header-button-minimize{
    width: 12px;
    height: 2px;
    margin: 4px 0;
    background-color: #e5c496;
}
.popup-content{
    background-color: #fff;
    padding: 0 15px 15px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}
.popup-input-block{
    display: flex;
    align-items: center;
    padding: 7px 0;
    border-bottom: 1px solid #979797;
}
.popup-input-label{
    display: inline-block;
    width: 60px;
    margin-right: 15px;
}
.popup-input{
    border: none;
    flex-grow: 1;
}
.popup-input:focus, .popup-input:focus{
    outline: none;
}
.popup-textarea-block{
    margin-top: 16px;
}
.popup-textarea{
    width: 100%;
    height: 300px;
    resize: none;
    border: none;
}
.popup-textarea:active, .popup-textarea:focus{
    outline: none;
}
.popup-buttons-block{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.popup-submit-button{
    border: none;
    background-color: #272727;
    color: #e5c496;
    /* padding: 13px 60px; */
    border-radius: 8px;
    width: 159px;
    height: 40px;
    font-family: ProximaNova;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
}
.popup-submit-button:hover{
    cursor: pointer;
}
.popup-submit-button:focus, .popup-submit-button:active{
    outline: none;
}
.popup-delete-button{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 11.5px;
}
.popup-delete-button:hover{
    cursor: pointer;
}
.popup-delete-button-icon{
    margin-bottom: 3px;
}
.popup-delete-button-text{
    font-size: 9px;
}


@media (max-width: 767px) {
    .popup-block{
        width: calc(100vw - 32px);
        right: 0;
    }
}