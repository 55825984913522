.privacy {
    .privacy-content {
        width: 1440px;
        margin: 0 auto;
    }
    .privacy-top {
        /* display: flex; */
        position: relative;
        margin-top: 90px;
    }
  .privacy-line-title{
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        margin-top: 90px;
  }
  .privacy-line {
        display: block;
        width: 140px;
        height: 1px;
        background: #272727;
    }
    h2.section-title {
        font-family: Prata;
        font-size: 56px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.18;
        letter-spacing: normal;
        color: #272727;
        margin-left: 24px;
      }
    .privacy-text {
        margin-top: 50px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 176px;
        :first-child {
        }
        &-content {
            column-count: 2;
            column-gap: 10%;
            margin: 0 164px;
        }
    }
    .pt-part-1, .pt-part-2 {
        width: 45%;
        /* height: 1453px; */
        object-fit: contain;
        font-family: ProximaNova;
        font-size: 14px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: normal;
        text-align: justify;
        color: #4c4c4c;
    }
    .pt-part-1 p, .pt-part-2 p {
        margin-bottom: 22px;
    }
    p.title-text {
        width: 329px;
        margin-left: 163px;
      /* height: 58px; */
        font-family: ProximaNova;
        font-size: 18px;
        font-weight: 300;
        font-style: normal;
        line-height: 1.61;
        letter-spacing: normal;
        color: #4c4c4c;
        margin-bottom: 53px;
    }

    table {
        border-collapse: collapse;
        margin-bottom: 20px;
      }
      th, td {
        border: 1px solid grey;
      }
    table td {
        height: 12px;
        object-fit: contain;
        font-family: ProximaNova;
        font-size: 9.7px;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #000;
        padding-top: 6px;
        padding-bottom: 12px;
        padding-left: 5px;
    }

      table tr.first-row {
        background-color: #3c3c3b;
        color: #fff;
      }
      table th {
          width: 160px;
        height: 12px;
        object-fit: contain;
        font-family: ProximaNova;
        font-size: 9.7px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #fefefe;
        padding-top: 6px;
        padding-bottom: 12px;
        text-transform: uppercase;
    }

    tr.second-row td {
        height: 12px;
        object-fit: contain;
        font-family: ProximaNova;
        font-size: 9.7px;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #000;
        padding-top: 6px;
        padding-bottom: 12px;
        text-align: center;
    }
    tr.special-row td {
        height: 12px;
        object-fit: contain;
        font-family: ProximaNova;
        font-size: 9.7px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #fefefe;
        padding-top: 6px;
        padding-left: 5px;
        padding-bottom: 12px;
        text-transform: uppercase;
        background-color: #3c3c3b;
    }

    .tariff-text {
        font-family: ProximaNova;
      font-size: 10px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: normal;
      color: #4c4c4c;
      width: 100%;
      border-bottom: 2px solid rgba(198, 198, 198, 0.6);
      padding-bottom: 40px;
      margin-bottom: 100px;
    }
}

@media (max-width: 767px) {
  .content-padding {
    padding-top: 27px;
  }

  .content-padding-title {
    position: relative;
    padding-top: 131px;
  }

  .content-padding-title:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 0;
    width: 92px;
    height: 92px;
    margin-left: -46px;
    background-repeat: no-repeat;
    background-position: center;
  }

  .content-padding-tariff:before {
    background-image: url(../../assets/images/tariff_ico.svg);
  }

  .section-title__cards {
    position: relative;
    padding-top: 135px;
  }

  .section-title__cards:before {
    content: '';
    position: absolute;
    width: 92px;
    height: 92px;
    left: 50%;
    top: 25px;
    margin-left: -46px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(../../assets/images/terms_ico.svg);
  }

  .privacy .privacy-content {
    width: auto;
  }

  .privacy .privacy-line {
    display: none;
  }

  .privacy h2.section-title {
    font-size: 28px;
    margin: 0 auto;
  }

  .privacy .privacy-line-title {
    margin: 0 0 16px 0;
  }

  .privacy .privacy-text {
    padding: 0 16px;
    margin: 0;
    line-height: 100%;
  }

  .privacy p.title-text {
    width: auto;
    margin: 0 24px 32px;
    color: #4C4C4C80;
    text-align: center;
  }

  .privacy .privacy-text-content {
    margin: 0;
    padding: 0;
    column-count: 1;
  }

  .privacy-text-content h2 {
    font-size: 18px;
    line-height: 28px;
  }

  .privacy-text-content h2.underlined {
    text-decoration: none;
  }

  .privacy-text-content p {
    font-size: 16px;
    line-height: 20px;
    color: rgba(76, 76, 76, 0.5);
  }

  .privacy-text-content ol {
    font-size: 16px;
    color: rgba(76, 76, 76, 0.5);
  }

  .privacy-text-content ol li {
    line-height: 20px;
  }
}
