.Products {
    background-color: #fafafa;
    padding-bottom: 50px;
}

@media (max-width: 767px) {
    .Products {
        position: relative;
        padding-top: 157px;
    }
    .Products::before {
        content: '';
        position: absolute;
        left: 50%;
        top: 27px;
        width: 92px;
        height: 92px;
        margin-left: -46px;
        background-position: top center;
        background-image: url(../../assets/images/insignia_cards_ico.svg);
        background-repeat: no-repeat;
    }
}