.content-UP{
    display: flex;
    justify-content: space-between;
    padding: 0;
}

.left-column {
    display: flex;
    /* align-items: center; */
    margin-top: 191px;
}

.content-form {
    margin-left: 161px;
    margin-right: 118px;
    margin-top: 50px;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.lineForm {
    width: 80.6%;
    margin-left: 161px;
    margin-top: 95px;
}

.bg {
    background-color: #fff;
}

.custom-cards-arrow-separator {
    right: -25px;
}

.slick-slide:last-child .custom-cards-arrow-separator {
    right: -1px;
}

.up-wrap {
    background-color: #fff;
    padding-bottom: 161px;
}

.content-form-eligibility-button .button {
    width: 282px;
    margin-top: 50px;
}

@media (max-width: 767px) {
    .up-wrap,
    .bg {
        background: #fafafa;
    }
    .bg {
        position: relative;
        padding-bottom: 1px;
        margin-bottom: 56px;
    }
    .bg:after {
        content: '';
        position: absolute;
        left: 16px;
        right: 16px;
        bottom: 0;
        border-bottom: 1px solid #0F0F11;
    }
    .up-wrap {
        padding: 0;
    }
    .left-column {
        display: block;
        margin: 0;
        padding-top: 24px;
    }
    .content-UP {
        display: block;
        padding-top: 100%;
        background: url(../../assets/images/eligibility_bg_mobile.png) top center no-repeat;
        background-size: 100% auto;
    }
        .content-UP img {
            display: none;
        }
    .content-form {
        display: block;
        padding: 21px;
        margin: 0 16px 35px;
        background: #fff;
    }
    .content-form-eligibility .input {
        width: 100%;
        height: 50px;
        font-size: 16px;
        padding: 0 16px;
        border: solid 1px #4C4C4C1A;
    }
    .content-form > .container {
        margin: 0 0 18px 0;
    }
    .lineForm {
        display: none;
    }
    .content-form-eligibility {
        position: relative;
        padding-bottom: 75px;
    }
    .content-form-eligibility-button {
        position: absolute;
        left: 21px;
        right: 21px;
        bottom: 21px;
    }
    .content-form-eligibility-button .button {
        width: 100%;
        margin-top: 0;
    }
}