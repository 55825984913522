.button {
  width: 100%;
  height: 40px;
  border-radius: 8px;
  border: 1px solid transparent;
  background-color: #272727;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

/*.button {*/
/*  width: 100%;*/
/*  height: 100%;*/
/*  border-radius: 8px;*/
/*  border: 1px solid transparent;*/
/*  background-color: #d7c29a;*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  cursor: pointer;*/
/*}*/

.whiteButton {
  width: 100%;
  /* height: 100%; */
  border-radius: 8px;
  border: 1px solid transparent;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.whiteButton:hover {
  border: 1px solid #e5c496!important;
  background-color: #e5c496!important;
}

.whiteButton:hover span {
  color: #fff!important
}

.button span {
  font-family: ProximaNova;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #d7c29a;
}

.button:hover span {
  color: #000000;
}

.whiteButton span {
  font-family: ProximaNova;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

.whiteButton:hover span {
  color: #d7c29a;
}

.whiteButton:hover {
  border: 1px solid #e5c496!important;
  background-color: #e5c496!important;
}

.whiteButton:hover span {
  color: #000!important
}

.button-bordered {
  width: 90px;
  height: 40px;
  display: flex;
  flex: 0 0 50%;
  background-color: #fff;
  border: 1px solid #272727;
  margin-left: 19;
}            

@media (max-width: 767px) {
  .button,
  .whiteButton {
    height: 50px;
  }
  .button span,
  .whiteButton span {
    font-size: 16px;
  }
  .button-bordered {
    width: auto;
    height: 50px;
  }
  .button-bordered span {
    font-size: 16px;
  }
}