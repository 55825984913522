.footer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: 205px;
  padding: 32px 31px 30px;
  /* position: absolute; */
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: space-between;
  background-color: #fff;
  margin-top: auto;
  box-sizing: border-box;
  z-index: 2;
}

.footer-nav-menu {
  width: 504px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.nav-menu-title {
  font-family: ProximaNova-Medium;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.6px;
  color: #4c4c4c;
  width: 100%;
  height: 20px;
  display: block;
}

.nav-menu-item {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.6px;
  font-stretch: normal;
  font-style: normal;
  color: #4c4c4c;
  width: 100%;
  height: 20px;
  display: block;
  cursor: pointer;
}

.nav-menu-item:hover {
  text-decoration: underline;
}

.footer-nav-menu div {
  flex: 0 0 33%;
  flex-wrap: wrap;
  padding-left: 36px;
}

.footer-nav-menu div:first-child {
  flex-basis: auto;
  flex-grow: 1;
  margin-bottom: 20px;
}

.footer-links {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}

.footer-links-social {
  display: flex;
}

.insta {
  margin-right: 22px;
}

.linkedin {
  margin-right: 30px;
}

.copyrights {
  align-self: flex-end;
  font-family: ProximaNova;
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.6px;
  color: #525252;
}

.appStore {
  margin: 27px 0 auto;
}

.loggedOut {
  justify-content: unset;
  display: block;
}

@media (max-width: 767px) {
  .footer {
    position: relative;
    padding: 0 0 52px 0;
    width: 100%;
    height: auto;
    flex-direction: column-reverse;
  }

  .footer-links {
    width: 100%;
  }

  .footer-links-social {
    width: 100%;
    padding: 16px;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    border-bottom: 1px solid #4C4C4C1A;
  }

  .footer-links-socials img {
    width: 24px;
  }

  .linkedin {
    margin-right: 0;
  }

  .footer-nav-menu {
    width: 100%;
    padding: 32px 0;
    flex-direction: column;
    text-align: center;
  }

  .footer-nav-menu > div {
    padding: 0;
    margin-bottom: 32px;
  }

  .footer-nav-menu > div:last-child {
    margin-bottom: 0;
  }

  .footer-nav-menu div:first-child {
    display: none;
  }

  .nav-menu-title {
    font-size: 22px;
    margin-bottom: 12px;
  }
  
  .nav-menu-item {
    font-size: 16px;
    margin-bottom: 8px;
  }
  
  .nav-menu-item:last-child {
    margin: 0;
  }

  .copyrights {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 52px;
    padding: 16px;
    border-top: 1px solid #4C4C4C1A;
    font-size: 16px;
    text-align: center;
  }
}
