.cards-page {
	padding-top: 100px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-family: ProximaNova, sans-serif;
	color: #4c4c4c;
	background-color: #fafafa;
	.tabs {
		margin-top: 50px;
		ul {
			margin: 0;
		}
	}
}
.cards-page-card-wrapper {
	margin-bottom: 91px;
	> div:first-of-type {
		display: flex;
		justify-content: center;
	}

}
.cards-page-right-block {
	margin-left: 23px;
	.statements {
		margin-top: 17px;
		div {
			margin-bottom: 10px;
		}
	}
	.text-red {
		color: #d0021b;
	}
	.link-to {
		background: #fff;
		width: 399px;
		height: 46px;
		border-radius: 5px;
		border: solid 2px #f2f3f5;
		color: #d0021b;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 17px 0 11px;
		cursor: pointer;
		> div:first-of-type {
			display: flex;
			align-items: center;
		}
		.sign {
			border-radius: 50%;
			background-color: #b70000;
			color: #fff;
			margin: 0px 13px 0 0;
			width: 22px;
			height: 22px;
			display: inline-block;
			text-align: center;
			font-weight: bold;
		}
		.payment-arrow {
			width: 9px;
			height: 9px;
			transform: rotate(-45deg);
			-webkit-transform: rotate(-45deg);
			text-align: right;
			border: solid #c1c1c1;
			border-width: 0 2px 2px 0;
			display: inline-block;
			padding: 3px;
		}
	}
}
.active-wrapper {
	.cards-page-card-wrapper {
		// opacity: 0.4;
		display: none;
	}
	.card-active {
		// opacity: 1;
		display: block;
	}
}
.table {
	font-family: ProximaNova, sans-serif;
	border-collapse: collapse;
	margin-top: 60px;
	th {
		text-transform: uppercase;
		background-color: #272727;
		padding: 20px 40px;
		font-size: 16px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: center;
		color: #e5c496;
	}
	td {
		text-align: center;
		padding: 4px 20px;
	}
	table, th, td {
		border-right: solid 1px #979797;
	}
	tr {
		border-bottom: solid 2px #fff;
	}
	tbody tr {
		background-color: #efefef;
		&:nth-child(even) {
			background-color: #e6e6e6;
		}
	}
	.action {
		display: flex;
		align-items: center;
		justify-content: center;
		> div {
			margin: 10px;
		}
		.action-text {
			font-size: 9px;
			color: #4a4a4a;
		}
	}
}

.clicked-item {
	cursor: pointer;
}

.modal-unblock {
	margin-top: -30px;
	width: 518px;
	font-size: 18px;
}

.modal-unblock-inner {
	font-size: 16px;
	font-weight: 500;
	font-family: ProximaNova;
}

.modal-unblock-radios {
	margin-top: 50px;
	padding: 0 110px;
}

.modal-unblock-buttons button {
	width: 293px;
	margin-bottom: 121px;
}

.modal-stolen-buttons  button {
	max-width: 302px;
	margin-bottom: 81px;
}

.modal-stolen-comment {
	padding: 0 110px;
}

.modal-stolen-comment textarea {
	resize: none;
	width: 100%;
	margin-top: 15px;
	font-family: ProximaNova;
	background-color: rgb(250, 250, 250);
	border: none;
	border-radius: 3px;
}

@media (max-width: 767px) {
	.cards-page {
		display: block;
		padding-top: 0;
	}
	// .cards-page-card-wrapper {
	// 	// display: none;
	// }
	// .cards-page-mobile .slick-track {
	// 	display: flex;
	// }
	// .cards-page-mobile .slick-track .slick-slide {
	// 	float: none;
	// 	width: 100vw;
	// }
	.cards-list-mobile {
		background-color: #D7C29A;
	}

	.cards-list-mobile-list {
		position: relative;
		padding: 32px 0 52px 0;
	}

	.cards-list-mobile-list .slick-prev,
	.cards-list-mobile-list .slick-next {
		display: none !important;
	}

	.cards-list-mobile-list .slick-list {
		padding-left: 20px;
	}

	.cards-list-mobile--item {
		padding: 0 0 0 8px;
	}
	.cards-list-mobile--item-inner {
		width: calc(100vw - 54px);
		aspect-ratio: 320/205;
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
	}
	.cards-list-mobile--item-inner img {
		// box-shadow: 0px 20px 60px -15px rgba(0, 0, 0, 0.54);
		border-radius: 10px;
	}

	.cards-list-mobile--card-detail {
		padding: 16px 16px 70px;
		border-radius: 12px 12px 0 0;
		background-color: #FAFAFA;
	}

	.cards-list-mobile--card-balance {
		// margin-bottom: 20px;
	}

	.cards-list-mobile--card-balance > div {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 16px 0;
		font-size: 16px;
		color: #0F0F11;
		border-bottom: 1px solid #4C4C4C10;
	}

	.cards-list-mobile-dots {
		position: absolute;
		left: 50%;
		bottom: 25px;
		display: flex;
		gap: 8px;
		justify-content: center;
		margin: 0 auto;
		list-style: none;
		padding: 0;
		transform: translateX(-50%);
	}

	.cards-list-mobile-dots li {
		width: 8px;
		height: 8px;
		background-color: rgba(76, 76, 76, 0.5);
		border-radius: 50%;
		list-style: none;
		margin: 0;
		padding: 0;
	}

	.cards-list-mobile-dots li.active {
		background-color: #0F0F11;
	}
	.cards-button {
		height: 60px;
		padding-top: 10px;
		margin-bottom: 12px;
	}
	.cards-button:last-child {
		margin-bottom: 0;
	}
	.cards-button .button {
		position: relative;
		width: 100%;
		height: 60px;
		padding: 0 60px;
		justify-content: flex-start;
		margin: 0;
		background: #fff;
	}
	.cards-button .button span {
		color: #0F0F11;
		font-size: 18px;
		font-family: Prata;
	}
	.cards-button .button:before {
		content: '';
		position: absolute;
		left: 16px;
		top: 50%;
		width: 34px;
		height: 34px;
		margin-top: -17px;
		background-position: center;
		background-repeat: no-repeat;
	}
	.cards-button-manage .button::before {
		background-image: url(../../assets/images/cards/manage_ico.svg);
	}
	.cards-button-invoices .button::before {
		background-image: url(../../assets/images/cards/invoices_ico.svg);
	}
	.cards-button-statements .button::before {
		background-image: url(../../assets/images/cards/statements_ico.svg);
	}
	.cards-button-name .button::before {
		background-image: url(../../assets/images/cards/name_ico.svg);
	}
	.cards-button-pin .button::before {
		background-image: url(../../assets/images/cards/pin_ico.svg);
	}
	.cards-button-block .button::before {
		background-image: url(../../assets/images/cards/block_ico.svg);
	}
	.cards-button-stolen .button::before {
		background-image: url(../../assets/images/cards/stolen_ico.svg);
	}
	.cards-button .button:after {
		content: '';
		position: absolute;
		right: 16px;
		top: 50%;
		width: 34px;
		height: 34px;
		margin-top: -17px;
		background-image: url(../../assets/images/arrow_mobile.svg);
		background-position: center;
		background-repeat: no-repeat;
	}

	.manage-cards-container {
		padding: 0 16px 48px;
	}

	.Content-mobile {
		padding-top: 30px;
	}

	.Content-mobile .cards-header {
		margin-bottom: 16px;
	}

	.cards-list-mobile--card-actions {
		padding: 32px 0;
	}

	.modal-unblock {
		margin-top: 0;
		width: auto;
		line-height: 120%;
		font-family: 'PRATA';
	}

	.modal-unblock-description {
		font-size: 16px;
		margin-bottom: 32px;
		color: #4C4C4C80;
		line-height: 130%;
	}

	.modal-unblock-inner {
		padding: 10px;
		border-radius: 10px;
		font-size: 18px;
		line-height: 120%;
		background-color: #D7C29A10;
		font-family: Prata;
	}

	.modal-unblock-inner span {
		display: block;
		font-size: 16px;
		margin-bottom: 8px;
		font-family: ProximaNova;
	}

	.modal-unblock-radios {
		padding: 0;
		border-top: 1px solid #4C4C4C10;
	}

	.modal-unblock-radios > div {
		padding: 15px 0;
		border-bottom: 1px solid #4C4C4C10;
		font-size: 16px;
	}

	.modal-unblock-buttons {
		display: flex;
		gap: 12px;
	}

	.modal-unblock-buttons button {
		width: 100%;
		margin-bottom: 0px;
	}

	.modal-stolen-buttons  button {
		max-width: unset;
		margin-bottom: 0;
	}

	.modal-stolen-comment {
		padding: 0;
	}

	.modal-stolen-comment textarea {
		border-radius: 10px;
		border: 1px solid #4C4C4C10;
	}

	.cards-header-invoices,
	.cards-header-statements {
		position: relative;
		padding-top: 130px;
	}

	.cards-header-invoices:before,
	.cards-header-statements:before {
	  content: '';
	  position: absolute;
	  left: 50%;
	  top: 27px;
	  width: 92px;
	  height: 92px;
	  margin-left: -46px;
	  background-position: top center;
	  background-repeat: no-repeat;
	}
	
	.cards-header-invoices:before {
		background-image: url(../../assets/images/cards/invoices_big_ico.svg);
	}
	
	.cards-header-statements:before {
		background-image: url(../../assets/images/cards/statements_big_ico.svg);
	}
}

@media (max-width: 767px) {
	.invoices {
		position: relative;
		margin: 0;
		padding: 134px 16px 16px;
		display: block;
	}
	.invoices:before {
		content: '';
		position: absolute;
		left: 50%;
		top: 27px;
		width: 92px;
		height: 92px;
		margin-left: -46px;
		background-position: top center;
		background-image: url(../../assets/images/letters_mobile.svg);
		background-repeat: no-repeat;
	}
	.invoices-table {
		margin: 0;
		padding: 0 16px;
	}
	.invoices-table table {
		display: block;
	}
	.invoices-table table thead {
		display: none;
	}
	.invoices-table table tbody {
		display: block;
		width: 100%;
	}
	.invoices-table table tr {
		position: relative;
		display: block;
		height: auto;
		padding: 16px;
		margin-bottom: 16px;
		background-color: #fff;
		border-radius: 8px;
	}
	.invoices-table table tr:nth-child(odd),
	.invoices-table table tr:nth-child(even) {
		background-color: #fff;
	}
	.invoices-table table td {
		width: 100%;
		display: flex;
		height: auto;
		padding: 6px 0;
		background-color: #fff;
		text-align: left;
		font-size: 16px;
		color: #0F0F11;
		border: none;
		justify-content: space-between;
	}
	.invoices-table table td:first-child {
		// height: 30px;
		margin-bottom: 24px;
		align-items: center;
	}
	.invoices-table table.table .action {
		display: block;
		padding: 0;
	}
	.invoices-table table .last-col {
		padding: 16px 0 0;
		margin: 0;
	}
	.invoices-table table .last-col > div {
		position: relative;
		width: 100%;
		height: 36px;
		padding: 0;
		margin: 0;
		gap: 8px;
		flex-direction: row;
		line-height: 36px;
		background: #FAFAFA;
	}
	.invoices-table table .last-col > div:before {
	  content: '';
	  position: absolute;
	  left: 0;
	  top: 5px;
	  bottom: 5px;
	  width: 1px;
	  background: #4C4C4C10;
	}
	.invoices-table table .last-col > div:first-child:before {
		display: none;
	}
	.invoices-table table .last-col .action-text {
		padding: 0;
		margin: 0;
		font-size: 16px;
	}
	.invoices-table table td.no-data {
		display: block;
		height: 180px;
		line-height: 180px;
		text-align: center;
	}
	.invoices-table table td:nth-child(2):before {
		content: 'Invoice';
		color: #4C4C4C80;
	}
	.invoices-table table td:nth-child(3):before {
		content: 'Amount';
		color: #4C4C4C80;
	}
	.statements-table table td:nth-child(2):before {
		content: 'Previous Balance';
		color: #4C4C4C80;
	}
	.statements-table table td:nth-child(3):before {
		content: 'New Credits';
		color: #4C4C4C80;
	}
	.statements-table table td:nth-child(4):before {
		content: 'New Charges';
		color: #4C4C4C80;
	}
	.statements-table table td:nth-child(5):before {
		content: 'New Balance';
		color: #4C4C4C80;
	}
	.invoices-table table td.balance-plus {
		color: #47e538;
	}
	.invoices-table table td.balance-minus {
		color: #E54338;
	}
	.badge-red {
		display: block;
		padding: 6px 8px;
		background: #E54338;
		color: #fff;
		font-size: 16px;
		border-radius: 8px;
	}
	.cards-list-mobile--card-balance div.badge-due-date {
		margin-bottom: 6px;
		margin-top: -18px;
		justify-content: center;
	}
	.cards-list-mobile--card-balance div.badge-due-date span {
		padding: 6px 8px;
		margin: 0 auto;
		background: #E54338;
		color: #fff;
		font-size: 16px;
		border-radius: 8px;
	}
	.balance-currency {
		color: #4C4C4C80;
	}
}
