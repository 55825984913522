.screen {
  background: #fafafa;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.screen__content {
  flex-grow: 1;
  position: relative;
}

@media (max-width: 767px) {
  .screen__content {
    padding-top: 0;
  }
  .white-header .screen__content {
    padding-top: 50px;
  }
}