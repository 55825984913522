.logo-box {
  width: 50px;
  height: 50px;
  position: absolute;
  z-index: 16;
  left: 67px;
  top: 10px;
}
.auth-modal {
  max-width: 430px;
  margin: 0 auto;
}
.auth-successful {
  width: 320px;
}
.auth-form {
  width: 590px;
  /*width: 500px;*/
}
.auth-content {
  display: flex;
  width: 1440px;
  /*max-width: 1440px;*/
  height: 702px;
  margin: 0 auto;
}
.auth-c1 {
  width: 140px;
}
.auth-line {
  border-top: #000000 1px solid;
  margin-top: 125px;
}
.auth-c2 {
  width: 100%;
  height: 702px;
  text-align: left;
  padding-left: 39px;
  background-repeat: no-repeat;
  background-position: right;
}
.auth-t1 {
  margin-top: 103px;

  font-family: Prata;
  font-size: 56px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  color: #272727;
}
.auth-t2 {
  font-family: ProximaNova;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.61;
  letter-spacing: normal;
  color: #4c4c4c;
}

.auth-t2::before {
  content: '';
  position: absolute;
  left: 0;
  margin-top: calc(9px * 1.61);
  width: 125px;
  height: 1px;
  background-color: #000;
}

.auth-row {
  margin-top: 15px;
}

/* input */
.auth-input {
  margin-top: 9px;
}
.auth-input-label {
  display: block;

  font-family: ProximaNova;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}
.auth-input-row {
  margin-top: 10px;
  display: flex;
}
.auth-input-input {
  width: 270px;
  min-width: 270px; /* //lk */
  height: 40px;
  padding: 0 10px;
  outline: none;
  border-radius: 8px;

  border: solid 1px #000000;
  font-family: ProximaNova;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}
.auth-input-error {
  margin-left: 10px;
  align-items: center;
}
/* endinput */

/* error */
.auth-error {
  display: none;
  /*margin-left: 10px;*/
  /*align-items: center;*/
}
.auth-has-error {
  display: flex;
}
.auth-error-i {
  min-width: 12px;
  width: 12px;
  height: 13px;
  border-radius: 12px;
  margin-right: 5px;

  background-color: #d0021b;
  font-family: ProximaNova;
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}
.auth-error-t {
  text-transform: uppercase;

  font-family: ProximaNova;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.39px;
  color: #d0021b;
}
/* end-error */

/* group4 */
.auth-t2 {
  margin-top: 7px;
  margin-bottom: 30px;
}
.auth-t3 {
  cursor: pointer;

  font-family: ProximaNova;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}
/* end-group4 */

/* btn*/
.auth-btn-group {
  margin-top: 20px;
  align-items: center;
  display: flex;
}
.auth-btn {
  height: 40px;
  border-radius: 8px;
  border: solid 1px #000000;
  cursor: pointer;

  font-family: ProximaNova;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 40px;
  letter-spacing: normal;
  text-align: center;
}
.auth-b1 {
  width: 138px;
  margin-right: 15px;
  color: #000000;
}
.auth-b2 {
  width: 138px;
  margin-right: 15px;
  color: #d7c29a;
  background-color: #272727;
}
.auth-b3 {
  width: 292px;
  color: #d7c29a;
  background-color: #272727;
}
.auth-b3:hover {
  color: #272727 ;
  background-color: #d7c29a;
  border-color: transparent;
  transition: all ease-in .1s;
}

.auth-btn-loader {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* end-btn*/

/* code */
.auth-code-input {
  display: flex!important;
  flex-wrap: nowrap;
}
.auth-code-group {
  width: 294px;
  margin-top: 15px;
  text-align: center;
}
.auth-code-label {
  display: block;
  margin-bottom: 10px;

  font-family: ProximaNova;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.45px;
  color: #88888b;
}
.auth-code-input input{
  width: 39px;
  border-radius: 2px;
  font-size: 18px;
  height: 39px;
  margin-right: 4px;
  border: 1px solid #262629;
  overflow: hidden;
  outline: none;
  text-align: center;
  color: #000000;

  font-family: LucidaGrande;
}
.auth-code-input input:focus{
  border: 1px solid #e5c496;
}
.auth-code-error {
  margin-top: 10px;
  align-items: center;
  justify-content: center;
}
.auth-code-t1 {
  text-transform: uppercase;
  margin-top: 15px;

  font-family: ProximaNova;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.39px;
  color: #272727;
}
/* end-code */

/* registercode */
.registercode-t2 {
  width: 394px;
  margin-top: 30px;

  font-family: ProximaNova;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}
/* registercode-end */

/* recoverycode */
.recoverycode-t2 {
  width: 517px;
  margin-top: 30px;

  font-family: ProximaNova;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  color: #000000;
}
/* recoverycode-end */

/* logincode */
.logincode-t2 {
  width: 394px;
  margin-top: 30px;

  font-family: ProximaNova;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}
/* logincode-end */

/* login-remember */
.login-group3 {
  margin-top: 12px;
  align-items: center;
  display: flex;
}
.login-remember {
  cursor: pointer;
}
.login-group3-t {
  margin-left: 10px;

  font-family: ProximaNova;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}
/* login-remember-end */

/* register */
.register-i2{
  width: 32px;
  min-width: 32px;
}
.register-i3{
  width: 207px;
  min-width: 207px;
  margin-left: 10px;
}
/* register-end */

/* masterkey */
.masterkey-group {
  margin-top: 15px;
  align-items: center;
  display: flex;
}
.masterkey-i {
  width: 55px;

  font-family: Prata;
  font-size: 40px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.65;
  letter-spacing: normal;
  text-align: center;
  color: #272727;
}
.masterkey-t {
  margin-left: 10px;

  width: 369px;
  height: 47px;
  font-family: ProximaNova;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}
/* masterkey-end  */



/* Auth left */
.auth-content{
  overflow: hidden;
}
.auth-left{
  width: 40%;
  position: relative;
  z-index: 15;
}
.auth-c2{
  padding-left: 150px;
}
.auth-c1, .auth-c2{
  z-index: 99;
  position: relative;
}
.auth-left::after{
  content: '';
  position: absolute;
  top: -30%;
  left: -50%;
  width: 1000px;
  height: 1000px;
  background-color: #fff;
  z-index: 20;
  transform: rotate(17deg);
}


/* Auth right */
.auth-right{
  width: 60%;
  position: relative;
  z-index: 10;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.auth_right_content{
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 0 5% 0 20%;
  color: #fff;
}
.auth_right_content span{
  font-family: Prata !important;
  font-size: 30px;
  width: 50%;
}

.news_link{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.news_link p{
  font-size: 16px;
  color: #d7c29a;
  font-weight: bold;
  margin-top: 5px;
}
.news_link svg{
  padding: 15px;
  background-color: #d7c29a; 
  border-radius: 50%;
  color: #000;
  font-size: 30px;
}
.news_link::after{
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: #d7c29a;
  transition: width .3s;
  margin-top: 5px;
}
.news_link:hover::after{
  width: 100%;
  transition: width .3s;
}
.auth-container {
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.auth-modal-info {
  margin-top: 15px;
}

@media (max-width: 767px) {
  .logo-box {
    width: 100%;
    left: 0;
    top: 0;
    background: rgba(15, 15, 17, 0.3);
    z-index: 10;
  }

  .logo-box a {
    display: flex;
    width: 44px;
    height: 50px;
    align-items: center;
    margin: 0 auto;
  }

  .auth-content {
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    height: auto;
    z-index: 1;
  }

  .auth-form,
  .auth-form-recovery {
    position: relative;
    width: 100%;
    padding: 20px 16px 48px;
    background: #fff;
  }

  .auth-form-recovery:after {
    content: '';
    position: absolute;
    top: -25px;
    left: -50%;
    width: 200vw;
    height: 200px;
    background-color: #fff;
    z-index: 20;
    transform: rotate(-7deg);
    z-index: -1;
  }

  .auth-c1 {
    display: none;
  }

  .auth-c2 {
    height: auto;
    padding-top: 0;
    padding-left: 0;
  }

  .auth-t1 {
    margin-top: 0;
    font-size: 28px;
    text-align: center;
  }

  .auth-t2 {
    text-align: center;
    color: #4C4C4C80;
  }

  .auth-t2::before {
    display: none;
  }

  .auth-left {
    width: 100%;
  }

  .auth-left::after {
    top: -25px;
    width: 200vw;
    height: 200px;
    transform: rotate(-7deg);
  }

  .auth-btn {
    width: 100%;
    height: 50px;
    line-height: 50px;
    font-size: 16px;
  }

  .auth-btn-loader {
    width: 100%;
  }

  .auth-right {
    /* display: none; */
    width: 100%;
    padding-top: 100%;
  }

  .auth_right_content {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    padding: 47px 16px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
  }

  .auth_right_content span {
    width: 100%;
    margin-bottom: 14px;
    font-size: 28px;
    line-height: 36px;
  }

  .auth_right_content .news_link svg {
    display: none;
  }

  .auth-input-input {
    width: 100%;
    height: 50px;
    border-color: #4C4C4C1A;
    background: #FAFAFA;
  }

  .auth-input {
    margin-top: 16px;
  }

  .auth-input-row {
    display: block;
  }

  .auth-input-label {
    color: #4C4C4C80;
  }

  .login-group3 {
    margin-top: 16px;
  }

  .auth-btn-group {
    margin-top: 32px;
  }

  .auth-c2-recovery {
    padding-top: 100%;
    background-position: top right;
    background-size: auto 60%;
  }

  .auth-c2-masterkey {
    padding-top: 100%;
    background-position: center -85%;
    background-size: auto 70%;
  }

  .auth-modal {
    color: #4C4C4C80;
    font-size: 16px;
    line-height: 22px;
  }
  .auth-modal p {
    font-size: 16px;
    line-height: 22px;
    font-family: ProximaNova;
  }

  .auth-modal-info {
    margin: 15px 24px 0;
    padding: 8px;
    background: #D7C29A1A;
    border-radius: 8px;
  }

  .auth-modal-info p {
    color: #000;
  }

  .recovery-custom-modal .close-icon {
    display: none;
  }

  .recovery-custom-modal .title {
    position: relative;
    padding-top: 130px;
    text-align: center;
    border: none;
    height: auto;
    line-height: 100%;
    font-family: Prata;
  }

  .recovery-custom-modal .title:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 24px;
    width: 92px;
    height: 92px;
    margin-left: -46px;
    background-position: center;
    background-repeat: no-repeat;
  }

  .recovery-custom-modal.login-error-modal .title:before {
    background-image: url(../../assets/images/auth/auth_error_ico.svg);
  }

  .recovery-custom-modal.login-success-modal .title:before {
    background-image: url(../../assets/images/auth/auth_success_ico.svg);
  }

  .logincode-t2 {
    color: rgba(76, 76, 76, 0.5);
  }

  .auth-code-label {
    font-size: 16px;
  }
  
  .auth-btn-group {
    width: 100%;
  }

  .logincode-t2 {
    width: 100%;
    color: rgba(76, 76, 76, 0.5);
  }

  .auth-code-t1 {
    text-transform: none;
    font-size: 16px;
  }

  .auth-code-group {
    width: 100%;
  }

  .auth-code-input {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
  }

  .auth-code-input input {
    width: 44px;
    height: 44px;
    border-radius: 6px;
    border: 1px solid rgba(76, 76, 76, 0.5);
  }
}