
.tariffs-list {
  display: none;
}

@media (max-width: 767px) {
  .tariffs-table {
    display: none;
  }
  .tariffs-list {
    display: block;
    padding: 0 16px;
    font-size: 13px;
  }
  .tariffs-list-item__title {
    position: relative;
    padding: 16px 0;
    font-weight: bold;
    text-transform: uppercase;
  }
  .tariffs-list-item__title:after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    background-image: url(../../assets/images/arrow_mobile.svg);
    background-position: center;
    background-repeat: no-repeat;
  }
  .tariffs-list-item__title.active:after {
    transform: rotate(90deg);
  }
  .tariffs-list-item {
    border-bottom: 1px solid rgba(76, 76, 76, 0.1);
  }
  .tariffs-list-item .tariffs-list-item {
    border-bottom: 0;
  }
  .tariffs-list-item-inner,
  .tariffs-list-item-subinner {
    display: none;
  }
  .tariffs-list-item-inner.active,
  .tariffs-list-item-subinner.active {
    display: block;
  }
  .tariffs-list-item .tariffs-list-item .tariffs-list-item__title {
    font-weight: normal;
  }
  .tariffs-list-subitem {
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: space-between;
    min-height: 40px;
    padding: 8px 0;
  }
  .tariffs-list-item__subtitle {
    width: 50%;
    flex: none;
    color: #4C4C4C80;
  }
}