.preloader {
  align-items: center;
  background: white;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}